import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue'),
        meta: {
            title: 'Over Smarthome-aanbiedingen.nl'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.afterEach((to) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title
    } else {
        document.title = 'Smarthome-aanbiedingen.nl';
    }
});

export default router
