<template>
  <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    <h5 class="my-0 mr-md-auto font-weight-normal">Smarthome-aanbiedingen.nl</h5>
    <Menu/>
  </div>
</template>

<script>
import Menu from "@/components/Menu";

export default {
  props: {
    title: String
  },
  components: {
    Menu
  }
}
</script>